import api from 'Api/axios'
import Cookies from 'js-cookie'
import { sendMessageToApp } from 'Utils/message'

const PERSONAL = 'cGVyc29uYWw%3D'
const ENTERPRISE = 'qB225%7123%21'

export default {
  methods: {
    //앱인 경우 본인인증 페이지로 넘어가기전 기존 입력 데이터를 localstorage에 저장해두었다가 끄내온다
    onMobileCertRequestPersonal() {
      if (!this.isApp) return

      const value = {
        userId: btoa(this.userId),
        userPw: btoa(this.userPw),
        userPwRepeat: btoa(this.userPwRepeat),
        customerType: this.customerType,
        recommenderPhone: this.recommenderPhone,

        checkEmail: this.checkEmail,
        checkSms: this.checkSms,
        checkOpen: this.checkOpen,
        checkUserInfo: this.checkUserInfo,
        check3rd: this.check3rd,
        retentionPeriod: this.retentionPeriod,
      }

      //localStorage.setItem('personal', JSON.stringify(value))
      Cookies.set(PERSONAL, btoa(JSON.stringify(value)), {
        expires: 1 / 48,
        path: '/join',
      })
    },

    setPersonalDataFromLocalStorage() {
      const personalData = Cookies.get(PERSONAL) //localStorage.getItem('personal')
      console.error(personalData)
      if (personalData) {
        //본인인증 후 바로 진입한게 아니라면 ls를 초기화한다.
        if (!this.$route.query.certNum) {
          Cookies.remove(PERSONAL, { path: '/join' })
          //localStorage.removeItem('personal')
          return
        }

        const {
          userId,
          userPw,
          userPwRepeat,
          customerType,
          recommenderPhone,

          checkEmail,
          checkSms,
          checkOpen,
          checkUserInfo,
          check3rd,
          retentionPeriod,
        } = JSON.parse(atob(personalData))

        this.userId = atob(userId)
        this.userPw = atob(userPw)
        this.userPwRepeat = atob(userPwRepeat)
        this.customerType = customerType
        this.recommenderPhone = recommenderPhone
        this.checkEmail = checkEmail === 'Y' ? true : false
        this.checkSms = checkSms === 'Y' ? true : false
        this.checkOpen = checkOpen === 'Y' ? true : false
        this.checkUserInfo = checkUserInfo === 'Y' ? true : false
        this.check3rd = check3rd === 'Y' ? true : false
        this.retentionPeriod = retentionPeriod

        Cookies.remove(PERSONAL, { path: '/join' })
        //localStorage.removeItem('personal')
      }
    },

    onMobileCertRequestEnterprise() {
      sendMessageToApp('LOG', {
        message: 'onMobileCertRequestEnterprise1',
      })
      if (!this.isApp) return

      try {
        const value = {
          userId: btoa(this.userId),
          userPw: btoa(this.userPw),
          userPwRepeat: btoa(this.userPwRepeat),
          customerType: this.customerType,
          recommenderPhone: this.recommenderPhone,

          checkEmail: this.checkEmail,
          checkSms: this.checkSms,
          checkOpen: this.checkOpen,
          checkUserInfo: this.checkUserInfo,
          check3rd: this.check3rd,
          retentionPeriod: this.retentionPeriod,

          bizNo: this.bizNo,
          president: encodeURIComponent(this.president),
          companyNm: encodeURIComponent(this.companyNm),
        }

        Cookies.set(ENTERPRISE, btoa(JSON.stringify(value)), {
          expires: 1 / 48,
          path: '/join',
        })

        sendMessageToApp('LOG', {
          message: 'onMobileCertRequestEnterprise2',
          value: Cookies.get(ENTERPRISE),
        })
      } catch (e) {
        console.error(e)
        sendMessageToApp('LOG', {
          message: 'error onMobileCertRequestEnterprise2',
          value: e,
        })
      }

      //localStorage.setItem('enterprise', value)
    },

    setEnterpriseDataFromLocalStorage() {
      const enterpriseData = Cookies.get(ENTERPRISE) //localStorage.getItem('enterprise')
      sendMessageToApp('LOG', {
        message: 'setEnterpriseDataFromLocalStorage',
        enterpriseData,
      })

      if (enterpriseData) {
        //본인인증 후 바로 진입한게 아니라면 ls를 초기화한다. (본인인증 마무리 하지 않고, 페이지 이동했던 경우 정보가 남아있을 수 있으므로)
        if (!this.$route.query.certNum) {
          Cookies.remove(ENTERPRISE, { path: '/join' })
          //localStorage.removeItem('enterprise')
          return
        }

        const {
          userId,
          userPw,
          userPwRepeat,
          customerType,
          recommenderPhone,

          checkEmail,
          checkSms,
          checkOpen,
          checkUserInfo,
          check3rd,
          retentionPeriod,

          bizNo,
          president,
          companyNm,
        } = JSON.parse(atob(enterpriseData))

        this.userId = atob(userId)
        this.userPw = atob(userPw)
        this.userPwRepeat = atob(userPwRepeat)
        this.customerType = customerType
        this.recommenderPhone = recommenderPhone

        this.checkEmail = checkEmail === 'Y' ? true : false
        this.checkSms = checkSms === 'Y' ? true : false
        this.checkOpen = checkOpen === 'Y' ? true : false
        this.checkUserInfo = checkUserInfo === 'Y' ? true : false
        this.check3rd = check3rd === 'Y' ? true : false
        this.retentionPeriod = retentionPeriod

        this.bizNo = bizNo
        this.president = decodeURIComponent(president)
        this.companyNm = decodeURIComponent(companyNm)

        Cookies.remove(ENTERPRISE, { path: '/join' })
        //localStorage.removeItem('enterprise')
      }
    },
    //필수 입력값 검사
    validateInput() {
      // SNS일 경우
      if (this.isKakao || this.isNaver) {
        /** fixme:: 위에서부터 순차적으로 접근한다. */
        // 이름은 한글, 성명에 합법한 한글인지 체크함.
        if (!this.certedUserNm) {
          this.$toasted.error('이름을 입력해주세요.')
          this.$refs['poneConfirm'].$refs['userNm'].focus()
          return false
        }
        if (this.valiSpcCh(this.certedUserNm)) {
          this.$toasted.error('이름에 특수문자는 사용이 불가능합니다.')
          this.$refs['poneConfirm'].$refs['userNm'].focus()
          return false
        }
        if (this.valiNum(this.certedUserNm)) {
          this.$toasted.error('이름에 숫자 사용이 불가능합니다.')
          this.$refs['poneConfirm'].$refs['userNm'].focus()
          return false
        }
        if (!this.validLength(this.certedUserNm)) {
          this.$toasted.error('이름은 두글자 이상 입력해주세요.')
          this.$refs['poneConfirm'].$refs['userNm'].focus()
          return false
        }
        //SNS가 아닐 경우
      } else if (!this.isKakao && !this.isNaver) {
        if (!this.userId) {
          this.$toasted.error('아이디를 입력해주세요.')
          this.$refs['userId'].focus()
          return false
        }

        //아이디 정합성 체크
        if (!this.validId()) {
          this.$refs['userId'].focus()
          this.$toasted.error(
            '4 ~ 20자의 영문, 숫자와 특수문자(_)만 사용 가능합니다.',
          )
          return false
        }
        if (!this.validPassword()) {
          this.$toasted.error(
            '영문자/숫자/특수문자를 2가지 이상 조합하여 8자리 이상 입력해주세요.',
          )
          this.$refs['userPw'].focus()
          this.$refs['userPw'].value = ''
          this.$refs['userPwRepeat'].value = ''
          return false
        }

        //비밀번호 확인 입력 체크
        if (!this.userPwRepeat) {
          this.$refs['userPwRepeat'].focus()
          this.$toasted.error('비밀번호 확인을 입력해주세요.')
          return false
        }

        //비밀번호 확인 일치 체크
        if (!this.validPasswordRepeat()) {
          this.$toasted.error('비밀번호가 일치하지 않습니다')
          this.$refs['userPwRepeat'].focus()
          return false
        }
      }
      //공통
      if (!this.address) {
        this.$toasted.error('주소를 입력해주세요.')
        this.$refs['address'].focus()
        this.findAddress()
        return false
      }

      if (!this.addressDetail) {
        this.$toasted.error('나머지 주소를 입력해주세요.')
        this.$refs['addressDetail'].focus()
        return false
      }
      // 서비스 이용약관 동의
      if (this.checkUserInfo === 'N') {
        this.$toasted.error('서비스 이용약관에 동의해주세요.')
        this.$refs['authAgreement'].$refs['checkUserInfo'].focus()
        return false
      }
      // 개인 정보보호 정책 동의
      if (this.checkOpen === 'N') {
        this.$toasted.error('개인 정보 보호 정책에 동의해주세요.')
        this.$refs['authAgreement'].$refs['checkOpen'].focus()
        return false
      }
      // 정보 유예 기간
      if (
        !this.retentionPeriod ||
        this.retentionPeriod == '' ||
        this.retentionPeriod == undefined ||
        this.retentionPeriod == null
      ) {
        this.$toasted.error('정보 보유 기간을 선택해주세요.')
        // this.$refs['checkOpen'].focus()
        return false
      }
      // 연락처 입력 체크, watch로 특별히 숫자를 명확하게 입력하게 해준다.
      // if (!this.phone) {
      //   this.$refs['phone'].focus()
      //   this.$toasted.error('연락처를 입력해주세요.')
      //   return false
      // }
      // 연락처 정합성 체크
      // if (!this.validPhone()) {
      //   this.$toasted.error('연락처는 "-" 없이 숫자만 입력해주세요')
      //   return false
      // }
      // 이메일 정합성 체크
      // if (!this.email) {
      //   this.$refs['phone'].focus()
      //   this.$toasted.error('이메일을 입력해주세요.')
      //   return false
      // }
      // if (!this.validEmail()) {
      //   this.$toasted.error('이메일 형식이 올바르지 않습니다.')
      //   this.$refs['email'].focus()
      //   return false
      // }
      return true
    },
    //특수문자
    valiSpcCh(ele) {
      const SpcCh = new RegExp(/[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g)
      if (!SpcCh.test(ele)) {
        return false
      }
      return true
    },

    //숫자
    valiNum(ele) {
      const Num = new RegExp(/[0-9]/g)
      if (!Num.test(ele)) {
        return false
      }
      return true
    },

    //길이
    validLength(data) {
      if (data.length < 2) {
        return false
      }
      return true
    },

    validName() {
      const name = new RegExp(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g)
      if (!name.test(this.userNm)) {
        return false
      }
      return true
    },

    //체크박스 검사
    validCheckInput() {
      // if (this.check3rd === 'N') return false // fixme:: 개인정보 제3자 제공 및 위탁사항 이용약관은 필수항목이 아님
      if (this.checkUserInfo === 'N') return false
      if (this.checkOpen === 'N') return false

      return true
    },

    validPasswordRepeat() {
      if (this.userPwRepeat !== this.userPw) return false

      return true
    },

    //비밀번호 규칙 정규식 검사
    validPassword() {
      let typeCount = 0
      if (/[0-9]/.test(this.userPw)) typeCount++
      if (/[a-z]/.test(this.userPw)) typeCount++
      if (/[A-Z]/.test(this.userPw)) typeCount++
      if (/[$.$,$!$@$#$$$%]/.test(this.userPw)) typeCount++

      if (typeCount < 2) return false

      if (this.userPw.length < 8) return false

      return true
    },

    validId() {
      var regType = /^[A-Za-z0-9_]{4,20}$/
      if (!regType.test(this.userId)) {
        return false
      }
      return true
    },

    validEmail() {
      const email = new RegExp(/[-\d\S.+_]+@[-\d\S.+_]+\.[\S]{2,4}/)
      if (!email.test(this.email)) return false
      return true
    },

    validPhone() {
      if (!/^[0-9]*$/.test(this.phone)) return false
      return true
    },

    validCheck() {
      if (!this.validateInput()) {
        return false
      }

      return true
    },

    validBizNo() {
      const numberMap = this.bizNo
        .replace(/-/gi, '')
        .split('')
        .map(function (d) {
          return parseInt(d, 10)
        })
      if (numberMap.length == 10) {
        var keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5]
        var chk = 0
        keyArr.forEach(function (d, i) {
          chk += d * numberMap[i]
        })
        chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10)
        return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10
      }
      return false
    },

    userIdWatch(id) {
      const replacedId = id.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '')
      this.userId = replacedId
    },

    chkPwIsEqual() {
      if (this.userPwRepeat.length > 0 && this.userPw != this.userPwRepeat) {
        this.$toasted.error('비밀번호와 일치하지 않습니다.')
        this.userPwRepeat = ''
      }
    },

    async chkDupUserId() {
      if (this.userId.length >= 4) {
        const response = await api('HOGOO_GET_IS_DUP_ID', {
          userId: this.userId,
        })
        if (response.status !== 0) {
          this.$toasted.error('이미 등록된 아이디입니다.')
          this.userId = ''
          this.$refs['userId'].focus()
        }
      } else {
        if (this.userId && !this.validId()) {
          this.$toasted.error(
            '4 ~ 20자의 영문, 숫자와 특수문자(_)만 사용 가능 합니다.',
          )
          this.userId = ''
          this.$refs['userId'].focus()
        }
      }
    },

    async chkDupBizNo() {
      if (this.bizNo) {
        if (!this.validBizNo()) {
          this.$toasted.error(
            '유효하지 않은 사업자 등록번호입니다. 다시 입력하세요.',
          )
          this.bizNo = ''
          this.$refs['bizNo'].focus()
          return
        }
        if (this.bizNo.length == 12) {
          const response = await api('HOGOO_GET_IS_DUP_BIZNO', {
            bizNo: this.bizNo.split('-').join(''),
          })
          if (response.status !== 0) {
            this.$toasted.error('이미 등록된 사업자 등록번호입니다.')
            this.bizNo = ''
            this.$refs['bizNo'].focus()
          }
        } else {
          this.$toasted.error(
            '유효하지 않은 사업자 등록번호입니다. 다시 입력하세요.',
          )
          this.bizNo = ''
          this.$refs['bizNo'].focus()
        }
      }
    },

    async chkRecoPhone() {
      if (
        /^[0-9]*$/.test(this.recommenderPhone) &&
        (this.recommenderPhone.length == 10 ||
          this.recommenderPhone.length == 11)
      ) {
        const response = await api('HOGOO_GET_VALID_RECOMMENDER', {
          phone: this.recommenderPhone,
        })
        if (response.status !== 0) {
          this.$toasted.error('해당 휴대폰번호가 존재하지 않습니다.')
          this.recommenderPhone = ''
          this.$refs['recommenderPhone'].focus()
        }
      } else {
        if (this.recommenderPhone) {
          this.$toasted.error('잘못된 휴대폰번호 입니다.')
          this.recommenderPhone = ''
          this.$refs['recommenderPhone'].focus()
        }
      }
    },

    async chkCompName() {
      if (this.affiliatedCompany) {
        const response = await api('HOGOO_GET_EXIST_BELONG_NAME', {
          companyNm: this.affiliatedCompany,
        })
        if (response.status !== 0) {
          this.$toasted.error('해당 회사가 존재하지 않습니다.')
          this.affiliatedCompany = ''
          this.$refs['affiliatedCompany'].focus()
        }
      }
    },

    pwWatch(pw) {
      const replacedPw = pw.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '')
      this.userPw = replacedPw
    },

    chkPwIsValid() {
      if (this.userPw && (this.userPw.length < 8 || !this.validPassword())) {
        this.$toasted.error(
          '영문자/숫자/특수문자를 2가지 이상 조합하여 8자리 이상 입력해주세요.',
        )
        this.$refs['userPw'].focus()
        this.$refs['userPw'].value = ''
        this.$refs['userPwRepeat'].value = ''
      }
    },

    pwRpWatch(pw) {
      const replacedPw = pw.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '')
      this.userPwRepeat = replacedPw
    },

    phoneWatch(phone) {
      const replacedPhone = phone.replace(/[^0-9]/g, '')
      // 만약 첫 세글자가 010 이 아니면, 삭제.
      if (replacedPhone.length === 1 && replacedPhone !== '0') {
        this.phone = ''
      } else if (replacedPhone.legnth === 2 && replacedPhone !== '01') {
        this.phone = ''
      } else if (replacedPhone.length === 3 && replacedPhone !== '010') {
        this.phone = ''
      } else if (replacedPhone.length > 11) {
        this.phone = replacedPhone.substr(0, 11)
      }
    },

    bizNoWatch(bizNo) {
      let replacedBizNo = bizNo.replace(/[^0-9]/g, '')
      let tmp = ''

      if (replacedBizNo.length > 10) replacedBizNo = replacedBizNo.substr(0, 10)

      if (replacedBizNo.length < 4) {
        this.bizNo = replacedBizNo
      } else if (replacedBizNo.length < 6) {
        tmp += replacedBizNo.substr(0, 3)
        tmp += '-'
        tmp += replacedBizNo.substr(3)
        this.bizNo = tmp
      } else if (replacedBizNo.length < 11) {
        tmp += replacedBizNo.substr(0, 3)
        tmp += '-'
        tmp += replacedBizNo.substr(3, 2)
        tmp += '-'
        tmp += replacedBizNo.substr(5)
        this.bizNo = tmp
      }
    },
  },
}
